import React from 'react';
import { News } from '../components'
import { Page } from '../components/shared';
import './main.css';

const App = () => {
  return (
    <main>
      <Page>
        <News/>
      </Page>
    </main>

  )
}

export default App
